<template>
  <div>
    <Navbar :title="type | formatType | capitalize" :hideSearch="false" />
    <div class="app-body" style="min-height:70vh">
      <div
        v-if="
          currentUser.id &&
            currentUser.role == 'free' &&
            item.premiumContent == true
        "
        class="columns mt-5"
      >
        <div class="column">
          <b-message type="is-info">
            <div class="columns is-vcentered">
              <div class="column">
                <b-icon icon="lock" class="pr-2" size="is-small"></b-icon>
                Premium Content - Upgrade your account to Researcher to gain
                access
              </div>
            </div>
          </b-message>
        </div>
      </div>
      <div v-if="!currentUser.id" class="columns mt-5">
        <div class="column">
          <b-message type="is-info">
            <div class="columns is-vcentered">
              <div v-if="!currentUser.id" class="column">
                <b-icon icon="lock" class="pr-2" size="is-small"></b-icon>
                You must be signed in to gain access
              </div>
              <div class="column is-narrow">
                <router-link :to="{ name: 'Login' }">
                  <b-button type="is-info">Sign In</b-button>
                </router-link>
              </div>
            </div>
          </b-message>
        </div>
      </div>
      <div v-if="type == 'PrintedBook'" class="columns mt-5">
        <div class="column">
          <b-message type="is-info">
            <div class="columns is-vcentered">
              <div class="column">
                <b-icon icon="book" class="pr-2" size="is-small"></b-icon>
                Contact librarian to issue this book
              </div>
            </div>
          </b-message>
        </div>
      </div>
      <div v-if="type == 'Research'" class="columns mt-5">
        <div class="column">
          <b-message type="is-info">
            <div class="columns is-vcentered">
              <div class="column">
                <b-icon icon="book" class="pr-2" size="is-small"></b-icon>
                Contact librarian to access this resarch article
              </div>
            </div>
          </b-message>
        </div>
      </div>
      <div v-if="type == 'Journal'" class="columns mt-5">
        <div class="column">
          <b-message type="is-info">
            <div class="columns is-vcentered">
              <div class="column">
                <b-icon icon="book" class="pr-2" size="is-small"></b-icon>
                Contact librarian to access this journal
              </div>
            </div>
          </b-message>
        </div>
      </div>
      <div class="columns my-5 pb-5">
        <div v-if="item" class="column is-multiline">
          <p class="text-subtitle bold">
            {{ type | formatType | capitalize }}
          </p>
          <div
            v-if="
              (currentUser.id && item.premiumContent == false) ||
                (currentUser.role != 'free' && item.premiumContent == true)
            "
            class="columns is-multiline m-0 mt-5"
          >
            <div v-if="type === 'image'" class="column is-12">
              <viewer>
                <img :src="item.attachmentURL" />
              </viewer>
              <p class="bold">
                <i class="mdi mdi-information-outline pr-1"></i> Click on the
                image for more options
              </p>
              <p class="mt-5">
                <a
                  v-if="
                    (currentUser.id && item.premiumContent == false) ||
                      (currentUser.role != 'free' &&
                        item.premiumContent == true)
                  "
                  :href="item.attachmentURL"
                  class="no-decor"
                >
                  <b-button type="is-info" icon-left="download">
                    Download Image
                  </b-button>
                </a>
              </p>
            </div>
            <div v-else-if="type === 'map'" class="column is-12">
              <viewer
                v-if="
                  (currentUser.id && item.premiumContent == false) ||
                    (currentUser.role != 'free' && item.premiumContent == true)
                "
              >
                <img :src="item.attachmentURL" />
              </viewer>
              <p
                v-if="
                  (currentUser.id && item.premiumContent == false) ||
                    (currentUser.role != 'free' && item.premiumContent == true)
                "
                class="bold"
              >
                <i class="mdi mdi-information-outline pr-1"></i> Click on the
                map for more options
              </p>
              <p class="mt-5">
                <a
                  v-if="
                    (currentUser.id && item.premiumContent == false) ||
                      (currentUser.role != 'free' &&
                        item.premiumContent == true)
                  "
                  :href="item.attachmentURL"
                  class="no-decor"
                >
                  <b-button type="is-info" icon-left="download">
                    Download Map
                  </b-button>
                </a>
              </p>
            </div>
            <div
              v-else-if="type === 'video'"
              class="video-thumbnail column is-12"
            >
              <div v-if="item.attachmentURL.includes('readerslounge')">
                <div
                  v-if="
                    (currentUser.id && item.premiumContent == false) ||
                      (currentUser.role != 'free' &&
                        item.premiumContent == true)
                  "
                >
                  <video
                    v-if="item.attachmentURL.includes('.mp4')"
                    height="80vh"
                    controls
                  >
                    <source :src="item.attachmentURL" type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                  <p v-else>
                    <i class="mdi mdi-information-outline mr-2"></i>
                    Playback not supported for this file type.
                  </p>
                </div>
                <p class="mt-5">
                  <a
                    v-if="
                      (currentUser.id && item.premiumContent == false) ||
                        (currentUser.role != 'free' &&
                          item.premiumContent == true)
                    "
                    :href="item.attachmentURL"
                    class="no-decor"
                  >
                    <b-button type="is-info" icon-left="download">
                      Download Video
                    </b-button>
                  </a>
                </p>
              </div>
              <div
                v-else-if="
                  (currentUser.id && item.premiumContent == false) ||
                    (currentUser.role != 'free' && item.premiumContent == true)
                "
                class="yt-video-container"
              >
                <iframe
                  class="yt-video"
                  :src="
                    'https://www.youtube.com/embed/' +
                      item.attachmentURL.split('v=')[1].split('&')[0]
                  "
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            <div
              v-else-if="type === 'audio'"
              class="column is-3 has-text-centered"
            >
              <img
                src="@/assets/img/audio_icon.svg"
                class="my-5 pt-5"
                style="height:20vh"
              />
              <audio
                v-if="
                  (currentUser.id && item.premiumContent == false) ||
                    (currentUser.role != 'free' && item.premiumContent == true)
                "
                controls
              >
                <source :src="item.attachmentURL" type="audio/ogg" />
                Your browser does not support audio playback.
              </audio>
              <p class="mt-5">
                <a
                  v-if="
                    (currentUser.id && item.premiumContent == false) ||
                      (currentUser.role != 'free' &&
                        item.premiumContent == true)
                  "
                  :href="item.attachmentURL"
                  class="no-decor"
                >
                  <b-button type="is-info" icon-left="download">
                    Download Audio
                  </b-button>
                </a>
              </p>
            </div>
            <div
              v-else-if="type === 'dataset'"
              class="column is-3 has-text-centered"
            >
              <img
                src="@/assets/img/dataset_icon.svg"
                class="my-5 pt-5"
                style="height:20vh"
              />
              <p class="mt-5">
                <a
                  v-if="
                    (currentUser.id && item.premiumContent == false) ||
                      (currentUser.role != 'free' &&
                        item.premiumContent == true)
                  "
                  :href="item.attachmentURL"
                  class="no-decor"
                >
                  <b-button type="is-info" icon-left="download">
                    Download Dataset
                  </b-button>
                </a>
              </p>
            </div>
            <div v-else-if="type === 'PrintedBook'" class="column is-3">
              <img v-if="item.coverPhoto" :src="item.coverPhoto" width="100%" />
              <img v-else src="@/assets/img/book_icon.svg" width="100%" />
            </div>
            <div v-else-if="type === 'eBook'" class="column is-3">
              <img v-if="item.coverPhoto" :src="item.coverPhoto" width="100%" />
              <img v-else src="@/assets/img/book_icon.svg" width="100%" />
            </div>
            <div v-else-if="item.coverPhoto" class="column is-3">
              <img v-if="item.coverPhoto" :src="item.coverPhoto" width="100%" />
            </div>
            <div v-else-if="type === 'Research'" class="column is-3">
              <img src="@/assets/img/research_icon.svg" width="100%" />
            </div>
            <div v-else-if="type === 'eResearch'" class="column is-3">
              <img src="@/assets/img/research_icon.svg" width="100%" />
            </div>
            <div v-else-if="type === 'Journal'" class="column is-3">
              <img src="@/assets/img/journal_icon.svg" width="100%" />
            </div>
            <div v-else-if="type === 'eJournal'" class="column is-3">
              <img src="@/assets/img/journal_icon.svg" width="100%" />
            </div>
            <div v-else-if="type === 'Newspaper'" class="column is-3">
              <img src="@/assets/img/newspaper_icon.svg" width="100%" />
            </div>
            <div v-else-if="type === 'eNewspaper'" class="column is-3">
              <img src="@/assets/img/newspaper_icon.svg" width="100%" />
            </div>
            <div v-else class="column is-3">
              <img src="@/assets/img/dataset_icon.svg" width="100%" />
            </div>

            <div class="column">
              <div class="columns is-mobile m-0">
                <div class="column p-0">
                  <p class="text-subtitle bold">
                    {{ item.title }}
                  </p>
                  <p class="">
                    {{
                      Array.isArray(item.authors)
                        ? item.authors.join(", ")
                        : item.authors | filter
                    }}
                  </p>
                </div>
              </div>

              <p
                v-if="expandDescription || item.description.length <= 330"
                class="mt-5"
              >
                {{ item.description }}
                <a
                  v-if="item.description.length > 330"
                  @click="() => (this.expandDescription = false)"
                >
                  Show Less</a
                >
              </p>
              <p v-else class="mt-5">
                {{ item.description.slice(0, 330) + "..." }}
                <a @click="() => (this.expandDescription = true)"> Read More</a>
              </p>
              <div class="mt-5">
                <span v-for="(keyword, index) in item.keywords" :key="index">
                  <a
                    style="no-decor"
                    :href="
                      '/search?searchQuery=' +
                        keyword +
                        '&searchBy=keywords&filterBy=' +
                        type
                    "
                  >
                    <b-tag
                      v-if="keyword != ''"
                      style="background-color: #9e060733;color:#9e0607"
                      type="is-info is-light"
                      size="is-medium"
                      class="mr-2 mb-3"
                      rounded
                    >
                      {{ keyword }}
                    </b-tag>
                  </a>
                </span>
              </div>
              <div class="mt-5">
                <span v-if="currentUser.role != 'free'">
                  <a v-if="resourceURL" :href="resourceURL">
                    <b-button type="is-info" icon-left="download">
                      Download
                    </b-button>
                  </a>
                </span>
                <router-link
                  v-if="
                    (currentUser.id && item.premiumContent == false) ||
                      (currentUser.role != 'free' &&
                        item.premiumContent == true)
                  "
                  :to="{
                    name: 'EBookReader',
                    query: { id: item._id, type: type }
                  }"
                >
                  <b-button
                    v-if="
                      type == 'eBook' ||
                        type == 'eResearch' ||
                        type == 'eNewspaper' ||
                        type == 'eJournal'
                    "
                    class="ml-2"
                    type="is-success"
                    icon-left="book-open-page-variant-outline"
                  >
                    Start Reading
                  </b-button>
                </router-link>
              </div>
            </div>
          </div>
          <hr />
          <div>
            <div v-for="(value, key) in item" :key="key">
              <div v-if="!hiddenTags.includes(key)" class="columns">
                <div class="column">
                  <p class="bold">{{ key | formatCamelCase }}</p>
                  <span v-if="key == 'weblink'">
                    <a :href="value" target="_blank"> {{ value }} </a>
                  </span>
                  <span v-else>
                    {{
                      Array.isArray(value) ? value.join(", ") : value | filter
                    }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="column">
          <div>
            <Loading />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/app/Navbar";
import Footer from "@/components/app/Footer";
import api from "@/services/dataService";
import Loading from "@/components/app/Loading";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import Vue from "vue";
Vue.use(VueViewer);

export default {
  name: "ItemDetail",
  components: { Navbar, Footer, Loading },
  data() {
    return {
      expandDescription: false,
      itemId: undefined,
      type: undefined,
      item: undefined,
      hiddenTags: [
        "title",
        "description",
        "keywords",
        "authors",
        "kidsContent",
        "premiumContent",
        "dateCreated",
        "lastUpdated",
        "paidContent",
        "type",
        "coverPhoto",
        "__v",
        "_id",
        "attachmentURL",
        "paid",
        "featured",
        "eNewspaperAttachment",
        "eBookAttachment",
        "eResearchReportAttachment",
        "eJournalAttachment"
      ]
    };
  },
  computed: {
    resourceURL() {
      return this.$store.state.resourceUrl;
    },
    currentUser() {
      return this.$store.state.currentUser;
    }
  },
  created() {
    if (this.$route.query.id) {
      this.itemId = this.$route.query.id;
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    this.getItemDetail();
  },
  methods: {
    async getItemDetail() {
      await api
        .get(this.generateGetDetailUrl(this.type) + this.itemId)
        .then(async res => {
          // sorting alphabetically and adding in item
          this.item = Object.keys(res.data.data)
            .sort()
            .reduce((obj, key) => {
              obj[key] = res.data.data[key];
              return obj;
            }, {});
          // fetching PDF Url if resource is Ebook (URLs generated at runtime through s3)
          const request = {
            resourceId: this.item._id,
            resourceType: this.type
          };
          await this.$store.dispatch("setResourceUrl", undefined);
          await this.$store.dispatch("getResourceUrl", request);
        })
        .catch(err => {
          Notification.error(
            Array.isArray(err.message) ? err.message[0] : err.message
          );
          return err;
        });
    },
    generateGetDetailUrl(type) {
      if (type == "PrintedBook") return "/books/printed-book/";
      if (type == "eBook") return "/books/e-book/";
      if (type == "Research") return "/research/research-article/";
      if (type == "eResearch") return "/research/eresearch-article/";
      if (type == "Journal") return "/journal/journal-article/";
      if (type == "eJournal") return "/journal/ejournal-article/";
      if (type == "Newspaper") return "/newspaper/newspaper-article/";
      if (type == "eNewspaper") return "/newspaper/eNewspaper-article/";
      if (type == "image") return "/digital/digital-media/";
      if (type == "video") return "/digital/digital-media/";
      if (type == "audio") return "/digital/digital-media/";
      if (type == "document") return "/digital/digital-media/";
      if (type == "dataset") return "/digital/digital-media/";
      if (type == "map") return "/digital/digital-media/";
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
.explore-box {
  background-color: #f8f8f8 !important;
}
.yt-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.yt-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}
.image1 {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
}
.image2 {
  display: none;
  position: absolute;
  width: 50%;
  top: 45%;
  left: 20%;
  opacity: 0.5;
}
</style>
